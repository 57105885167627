@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    font-family: "Inter", sans-serif;
}
#page{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    background-color: #E2E8F0;
    padding-top: 30px;
}
.wrap-login-page {
    width: 100%;
    max-width: 540px;
    margin: auto;
    text-align: center;
    min-height: 100vh;
    padding-bottom: 30px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: space-between;
  }
  .wrap-login-page .login-box {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px;
    gap: 40px;
    text-align: start;
  }
  .wrap-login-page.sign-up {
    padding-top: 54px;
    gap: 52px;
  }
  .body-text {
    color: var(--Body-Text);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .body-title {
    color: var(--Heading);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }
  
  .body-title-2 {
    color: var(--Heading);
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
  }
  .body-title-2 span {
    color: var(--Style);
  }
  .body-text {
    color: var(--Body-Text);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .icon-more {
    font-size: 24px;
    color: var(--Heading);
    cursor: pointer;
  }
  .tf-color-1 {
    color: red !important;
  }

  .tf-color {
    color: blue !important;
    text-decoration: none;
  }
  
  .tf-color-2 {
    color: var(--22-c-55-e) !important;
  }
  
  .tf-color-3 {
    color: var(--Heading) !important;
  }
  .okay{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tf-button {
    margin-top: 20px;
    width: 100%;
    text-decoration: none;
    height: 50px;
    padding: 15px 22px;
    color: #FFF;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    border: 1px solid #2275fc;
    border-radius: 12px;
    background-color: #2275fc;
    background-size: 100%;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .tf-button:hover {
    color: #2275fc;
    background-color: #FFF;
  }
  .tf-button:hover span {
    color: #FFF !important;
  }
  .tf-button i {
    font-size: 20px;
  }
  .tf-button.style-1 {
    color: #2275fc;
    background-color: #fff;
  }
  .tf-button.style-1:hover {
    color: #FFF;
    background-color:#2275fc;
  }
  .tf-button.style-2 {
    color: #575864;
    background-color: #FFF;
    border-color: #ECF0F4;
  }
  .tf-button.style-2:hover {
    color: #FFF;
    background-color: #2275fc;
  }
  .tf-button.w208 {
    width: 208px;
  }
  .tf-button.w230 {
    width: 230px;
  }
  .tf-button.w180 {
    width: 180px;
  }
  .tf-button.w128 {
    width: 128px;
  }
  
  .tf-button-funtion {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--Input);
    border-radius: 12px;
    padding: 14px 21px;
    cursor: pointer;
  }
  .tf-button-funtion i {
    color: var(--Body-Text);
    font-size: 20px;
  }
  .tf-button-funtion div {
    color: var(--Body-Text);
  }
  
  .tf-button-download {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid var(--Input);
    border-radius: 12px;
    cursor: pointer;
  }
  .tf-button-download i {
    color: #000000;
    font-size: 20px;
  }
  
  